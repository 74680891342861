import React, { FC } from 'react'
import styled from 'styled-components'

// -- Components --
import { Title } from '../components/Title'
import * as Hero from '../components/Hero'
import { Button } from '../components/Button'
import { BulletList, BulletListItem } from '../components/BulletList'
import {
  LargeBullet,
  LargeBulletContent,
  LargeBulletImage
} from '../components/LargeBullet'
import { BubbleImage } from '../components/BubbleImage'

// -- Images --
import heroSolutionsSrc from '../images/hero/hero-solutions.jpg'
import imgDashboardForgeSrc from '../images/solutions/solutions-mockup-hv-1.png'
import imgProcessSrc from '../images/solutions/solutions-diagram-all.png'
import imgIntegrationSrc from '../images/solutions/solutions-diagram-data.png'
import imgDashboardBonfireSrc from '../images/solutions/solutions-mockup-bonfire-1.png'
import imgSystemsSrc from '../images/solutions/solutions-image-coolers.jpg'
import imgInfrastructureSrc from '../images/solutions/solutions-diagram-infrastructure.png'
import imgInformationSrc from '../images/solutions/solutions-diagram-info.png'
import imgInsightsSrc from '../images/solutions/solutions-diagram-actions.png'
import imgBubbleSrc from '../images/solutions/img-bubble-solutions.jpg'

// Icons
import utilitiesSrc from '../images/icons/icon-solutions-utilities.svg'
import refrigerationSrc from '../images/icons/icon-solutions-refrigeration.svg'
import waterSrc from '../images/icons/icon-solutions-water.svg'
import bmsSrc from '../images/icons/icon-solutions-building-automation-system.svg'
import iotSrc from '../images/icons/icon-solutions-iot.svg'
import weatherSrc from '../images/icons/icon-solutions-weather.svg'
import elevatorsSrc from '../images/icons/icon-solutions-elevators.svg'
import thirdPartySrc from '../images/icons/icon-solutions-thirdparty.svg'
import meteringSrc from '../images/icons/icon-solutions-metering.svg'
import fireSrc from '../images/icons/icon-solutions-fire.svg'

// -- Utils --
import { displayFont, textFont } from '../styles/typography'
import { theme } from '../theme'

// -- Layout --
import { PageLayout } from '../layouts/PageLayout'
import { Section } from '../components'
import { Grid } from '../components/Grid'
import { AwardsAndFeatures } from '../components/AwardsAndFeatures'
import { Excerpt, ExcerptCopy, ExcerptH3 } from '../components/Excerpt'

// -- Custom Components --
const StyledSection = styled(Section)`
  padding-top: ${theme.spacing.ultraLoose};
  padding-bottom: ${theme.spacing.ultraLoose};
`

const StyledH2 = styled.h2`
  ${displayFont('large', 'bold')}
  margin-bottom: ${theme.spacing.default};
`

const PreHeading = styled.h4`
  ${displayFont('small', 'semiBold')}
  margin-bottom: ${theme.spacing.tight};
  color: ${theme.palette.orange};
`

const MediumStrongFont = styled.h3`
  ${displayFont('medium', 'bold')}
  margin-bottom: ${theme.spacing.default};
`
const SmallNormalFont = styled.p`
  ${displayFont('small', 'normal')}
`

const Body = styled.p`
  ${textFont('bodyLarge')}
`

// This grid will have the image taking a larger portion of
// the grid, so we make this one as a special one
const OperationalPerformanceGrid = styled(Grid)`
  margin-top: ${theme.spacing.ultraLoose};
`

const OperationalPerformanceImage = styled.img`
  grid-column: 1 / -1;
  max-width: 100%;
  width: 100%;

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    grid-column: 4 / span 5;
  }
`

const OperationalPerformanceList = styled.div`
  grid-column: 1 / -1;
  margin-bottom: ${theme.spacing.loose};

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    grid-column: 1 / span 3;
    margin-bottom: 0;
  }
`

const EvenlyTwoColumn = styled.div`
  display: grid;
  margin-top: ${theme.spacing.loose};
  align-items: center;
  justify-content: start;

  & > *:first-child {
    grid-column: 1 / -1;
    margin-bottom: ${theme.spacing.extraLoose};
  }
  & > *:last-child {
    grid-column: 1 / -1;
  }

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    gap: ${theme.spacing.extraLoose};

    & > *:first-child {
      grid-column: 1 / 4;
      margin-bottom: 0;
    }
    & > *:last-child {
      grid-column: 4 / 8;
    }
  }
`
const BuildingSystemsList = styled.div`
  max-width: ${theme.dimensions.app.maxWidth};
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: ${theme.spacing.default};

  > * {
    margin-right: ${theme.spacing.loose};
  }

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
  }
`

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
`

const StyledLargeBullet = styled(LargeBullet)`
  margin-bottom: ${theme.spacing.default};
  align-items: center;
  grid-column: 1/-1;

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    grid-column: 4 / span 4;
  }
`
const StyledLargeBulletContent = styled(LargeBulletContent)`
  margin-left: ${theme.spacing.default};
`

const Solutions: FC = () => {
  return (
    <PageLayout>
      <Title title="Smart Building Analytics Solutions - Bueno" />
      <Hero.Wrapper src={heroSolutionsSrc}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Optimize performance with Bueno Smart Building Analytics
            </Hero.Heading>
          </Hero.LayoutHeading>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <div style={{ marginTop: `${theme.spacing.extraLoose}` }}>
        <Excerpt>
          <ExcerptH3>
            Improve operational performance and exceed business goals
          </ExcerptH3>
          <ExcerptCopy>
            Your business relies on complex, and often siloed systems that are
            expensive to operate and pose a risk to your operational performance
            if they fail or run sub-optimally. Imagine having a single source of
            truth of your building operations so you can optimize performance
            and minimize risk.
          </ExcerptCopy>
        </Excerpt>
      </div>

      <div
        style={{ marginBottom: '56px', marginTop: theme.spacing.extraLoose }}
      >
        <OperationalPerformanceGrid>
          <OperationalPerformanceList>
            <BulletList>
              <BulletListItem>Exceed sustainability targets</BulletListItem>
              <BulletListItem>Reduce energy consumption</BulletListItem>
              <BulletListItem>Improve occupant experience</BulletListItem>
              <BulletListItem>
                Reduce operational and maintenance expenditures
              </BulletListItem>
              <BulletListItem>Reduce equipment downtime</BulletListItem>
              <BulletListItem>Increase asset reliability</BulletListItem>
            </BulletList>
          </OperationalPerformanceList>
          <OperationalPerformanceImage
            src={imgDashboardForgeSrc}
            alt="Improve operational performance and exceed business goals"
          />
        </OperationalPerformanceGrid>
      </div>

      <div
        style={{
          backgroundColor: theme.palette.lightYellow,
          boxShadow: theme.elevation.content
        }}
      >
        <StyledSection>
          <StyledH2>
            Discover how Bueno Smart Building Analytics solution works
          </StyledH2>
          <Body>
            Bueno’s industry leading Smart Building Analytics is designed to
            provide you with rich insights to drive smarter decisions. By
            identifying and collecting large quantities of data from your
            building’s systems, we process and analyze that data to provide
            meaningful insights that allow you and your teams to act
            accordingly.
          </Body>

          <div
            style={{ textAlign: 'center', marginTop: theme.spacing.ultraLoose }}
          >
            <Image src={imgProcessSrc} />
          </div>
        </StyledSection>
      </div>

      <StyledSection>
        <PreHeading>Buildings, Data & Integration</PreHeading>
        <StyledH2>Centrally Integrate Buildings and Data</StyledH2>
        <SmallNormalFont>
          Bueno Smart Building Analytics system unlocks your operational data
          and provides complete visibility of all your building's proprietary
          and historical data in one centralized location.
        </SmallNormalFont>
        <EvenlyTwoColumn>
          <BulletList>
            <BulletListItem>
              Open API architecture supports integration to 3rd party services
              and tools
            </BulletListItem>
            <BulletListItem>
              Is scalable to collect and monitor hundreds, if not thousands of
              buildings per client
            </BulletListItem>
            <BulletListItem>
              Interprets and analyses large volumes of event data from thousands
              of devices, endpoints, and applications in near real-time, 24/7
            </BulletListItem>
            <BulletListItem>
              Works across all property types of building complexity, size, and
              scale
            </BulletListItem>
          </BulletList>

          <Image src={imgIntegrationSrc} />
        </EvenlyTwoColumn>
      </StyledSection>

      <StyledSection>
        <MediumStrongFont style={{ marginBottom: theme.spacing.loose }}>
          We collect data from your building’s standalone systems including
        </MediumStrongFont>
        <BuildingSystemsList>
          <StyledLargeBullet>
            <LargeBulletImage src={utilitiesSrc} />
            <StyledLargeBulletContent>
              <Body>Utilities</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={refrigerationSrc} />
            <StyledLargeBulletContent>
              <Body>Refrigeration</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={waterSrc} />
            <StyledLargeBulletContent>
              <Body>Water</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={bmsSrc} />
            <StyledLargeBulletContent>
              <Body>BAS/BMS</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={iotSrc} />
            <StyledLargeBulletContent>
              <Body>IOT</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={weatherSrc} />
            <StyledLargeBulletContent>
              <Body>Weather</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={elevatorsSrc} />
            <StyledLargeBulletContent>
              <Body>Elevators</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={thirdPartySrc} />
            <StyledLargeBulletContent>
              <Body>3rd party tools</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={meteringSrc} />
            <StyledLargeBulletContent>
              <Body>Metering</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>

          <StyledLargeBullet>
            <LargeBulletImage src={fireSrc} />
            <StyledLargeBulletContent>
              <Body>Fire</Body>
            </StyledLargeBulletContent>
          </StyledLargeBullet>
        </BuildingSystemsList>
        <div>
          <Image src={imgSystemsSrc} />
        </div>
      </StyledSection>

      <StyledSection>
        <PreHeading>Infrastructure</PreHeading>
        <StyledH2>State of the art technology</StyledH2>
        <SmallNormalFont>
          Built on Google Cloud Services, we leverage Machine Learning with IoT
          data and infuse Artificial Intelligence and Predictive Analytics to
          provide you with faster insights. Ultimately enabling you to make
          faster and smarter decisions.
        </SmallNormalFont>

        <EvenlyTwoColumn>
          <div>
            <Body style={{ fontWeight: '700' }}>Security and Data Privacy</Body>
            <Body>
              Bueno is secure and reliable and can provide the necessary
              controls to satisfy regulatory requirements. Have comfort knowing
              that enterprises, Governments, and Defence from around the world
              choose to use Bueno Smart Building Analytics.
            </Body>
            <BulletList style={{ marginTop: theme.spacing.default }}>
              <BulletListItem>
                Standard security includes single sign-on authentication,
                access, and level control
              </BulletListItem>
              <BulletListItem>
                Dashboards to provide visuals and an audit trail of what is
                happening, what has been actioned, and by who
              </BulletListItem>
              <BulletListItem>
                Track regulatory requirements and report on asset compliance
              </BulletListItem>
            </BulletList>
          </div>
          <div>
            <Image src={imgInfrastructureSrc} />
          </div>
        </EvenlyTwoColumn>
      </StyledSection>

      <StyledSection>
        <PreHeading>Insights, Information & Users</PreHeading>
        <StyledH2>Unlock data & gain complete visibility</StyledH2>
        <SmallNormalFont>
          Unlock data and gain complete visibility in near real-time so you can
          make smart decisions with our library of pre-configured (or easily
          configured) reports and dashboards. Benchmark against business needs
          and measure performance on a range of value streams such as:
        </SmallNormalFont>

        <EvenlyTwoColumn>
          <div>
            <BulletList style={{ marginTop: theme.spacing.default }}>
              <BulletListItem>
                Energy and water efficiency optimization
              </BulletListItem>
              <BulletListItem>Refrigeration optimization</BulletListItem>
              <BulletListItem>Asset lifecycle optimization</BulletListItem>
              <BulletListItem>Data-driven maintenance</BulletListItem>
              <BulletListItem>Risk Management</BulletListItem>
              <BulletListItem>Indoor Environment Quality</BulletListItem>
              <BulletListItem>Warranty Management</BulletListItem>
            </BulletList>
          </div>
          <Image src={imgInformationSrc} />
        </EvenlyTwoColumn>
      </StyledSection>

      <StyledSection>
        <MediumStrongFont>Deeper Insights</MediumStrongFont>
        <SmallNormalFont>
          Bueno provides insights for any use case including managing building
          complexity, user type to adopting data-driven operational practices.
          We recognize the need to deploy different analytic solutions based on
          the customer's needs.
        </SmallNormalFont>

        <EvenlyTwoColumn>
          <div>
            <BulletList style={{ marginTop: theme.spacing.default }}>
              <BulletListItem>
                Symptomatic and basic fault finding to identify
                performance/efficiency slip and direct mandatory maintenance
                activities
              </BulletListItem>
              <BulletListItem>
                Root cause analysis to automate fault finding and diagnostics,
                validate corrective actions, and respond faster to
                reactive/service events
              </BulletListItem>
              <BulletListItem>
                Optimization through identifying new or improved strategies to
                operate your systems at higher efficiency and better quality of
                service
              </BulletListItem>
              <BulletListItem>
                Predictive analytics to identify assets at risk of failure ahead
                of time
              </BulletListItem>
              <BulletListItem>
                Custom solutions: We will work together through a systematic
                engagement and discovery process to deploy bespoke analytics to
                address your specific strategic goals
              </BulletListItem>
            </BulletList>
          </div>
          <Image src={imgDashboardBonfireSrc} />
        </EvenlyTwoColumn>
      </StyledSection>

      <StyledSection>
        <MediumStrongFont>Turning information into value</MediumStrongFont>
        <SmallNormalFont>
          We recognize that to deliver the best return on investment, you need
          to empower your team of various operational stakeholders to become
          active participants.
          <br />
          To ensure you and your team get value, Bueno offers a customer success
          team from onboarding, mobilization to ongoing support and best
          practices.
        </SmallNormalFont>
      </StyledSection>

      <div style={{ marginBottom: '56px' }}>
        <StyledSection>
          <PreHeading>Actions</PreHeading>
          <StyledH2>Actioning Insights</StyledH2>

          <EvenlyTwoColumn>
            <SmallNormalFont>
              The final piece of the puzzle is ensuring your team is actioning
              those insights to ensure you realize the benefits and savings.
              Associate individuals with assets and parts to be fixed with
              ticketing and workflow management and hold all operational
              stakeholders accountable if actions aren’t getting completed with
              status reporting. Improve communication between the team with
              Bueno’s user-friendly native service management platform, or
              integrate our analytics platform with your own enterprise CMMS /
              service management system.
            </SmallNormalFont>
            <Image src={imgInsightsSrc} />
          </EvenlyTwoColumn>
        </StyledSection>
      </div>

      <BubbleImage
        src={imgBubbleSrc}
        imagePosition="left"
        textPosition="center"
        maxHeight={640}
      >
        <StyledH2>Bueno Managed Services Offering</StyledH2>
        <SmallNormalFont style={{ fontWeight: 700 }}>
          Bueno hybrid SaaS + Managed Services
        </SmallNormalFont>
        <Body style={{ marginTop: theme.spacing.extraLoose }}>
          Don’t have the resources? Together with our team of experienced
          engineers, we work to understand your internal capabilities and
          strategy to develop a hybrid solution where our team (or partners)
          will complement any capability gap that might exist in adopting Bueno
          smart building analytics system.
        </Body>
        <Button style={{ marginTop: theme.spacing.extraLoose }} to="/contact">
          Talk To An Expert
        </Button>
      </BubbleImage>

      <div style={{ marginTop: '56px' }}>
        <AwardsAndFeatures />
      </div>
    </PageLayout>
  )
}

export default Solutions
